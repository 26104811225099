html, body {
    -webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;
    height: 100%;
}

.full-height {
    height: 100%;
    width: 100%;
}
#root{
    height: 100%;
}
#root>div {
    height: 100%;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
#widget{
    background: #0C0C0C;
    overflow-y: auto;
    font-size: 0.8rem;
}
.buttonss
{width: 50px; height: 50px;}
.legend2 {
    line-height: 18px;
    color: #495057;
}
.legend2 i {
    width: 18px;
    height: 18px;
    float: right;
    margin-left: 8px;
    opacity: 1;
}
.info2 {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: rgba(255,255,255,0.7);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
    text-align: right;
}
.info2 h4 {
    margin: 0 0 5px;
    color: #777;
}

.droplist{
    cursor: pointer !important;
}
.firstTable
{
    margin-top: 20px;
}

.selectIcon
{
    position: absolute;
    margin-left: -33px;
    padding: 8.5px;
    color: #495057;
    border-radius: 5px;
    border: 1px solid #ced4da;
    background-color: white;

    margin-left: -20px;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.form-control:focus {
    border-color: #ced4da !important;
    box-shadow: none !important;
}
.loader {
    margin: auto;
    margin-top: 25%;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.hideLoading{
    display: none !important;
}
.showLoading{
    display: block !important;
}
.col-3
{
    padding-right: 0px !important;
    padding-left: 0px !important;
    min-width: 300px;
    z-index: 100;
}

.col-9
{
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.map{
    z-index: 0;
}
.table-dark {

}
@media only screen and (max-width: 1400px) and (min-width: 1200px) {

    .col-9{
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
    .col-3{
        flex: 0 0 30% !important;
        max-width: 30% !important;
    }
}
@media only screen and (max-width: 1200px) {
    .sideMenuButton
    {
        display: block !important;
    }
    .col-9
    {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .loginContainer {
         width: 80% !important;
     }
}
@media only screen and (min-width: 1200px) {
    .loginContainer {
        width: 400px !important;
    }
}
.sortedDatasTable > tr:first-child{
    font-weight: 900;
}

.table-label{
    width: 100% !important;
    height: 100% !important;
    padding-top: 2px;
}

.table-radio{
    margin-right: 10px;
}


/* The container */
.containerWidget {
    display: block;
    position: relative;
    padding-left: 35px !important;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.containerWidget input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerWidget:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerWidget input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerWidget input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.containerWidget .checkmark:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
}

.loginContainer{
    margin-top: -140px;
    z-index: 10;
    position: absolute;
    max-width: 400px;
    background: #212529;
    border-radius: 5px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
}

.mainContainer{
    z-index: 5;
}
.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.type1{
    width: 70%;
    margin-left: auto !important;
    margin-right: auto !important;
}
.login-btn{
    border-radius: 5px !important;
}
.textbox{
    color: #0c0c0c !important;
}
.parentDisable{
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
    z-index: 3;
    height: 100%;
    width: 100%;
}

.loginContainer2{
    z-index: 4;
}

.type2 {
    padding-bottom: 20px;
    width: 60%;
    font-size: 80%;
    margin: auto;
    color: #737879;
    text-align: center;
    cursor: default;
}


.leaflet-bottom, .leaflet-right{

}

.leaflet-left{
    margin-bottom: 0px;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80% !important;
    height: 90% !important;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.type2 > p{
    margin-top: 10px !important;
    max-width: 204px;
    margin-left: auto;
    margin-right: auto;
}
.type2 > p > a{
    color: #007bff !important;
    cursor: pointer;
}
.type2 > p > a:hover{
    color: #0062cc !important;
    text-decoration: underline !important;
}

.modal-content>iframe{
    height: 100%;
}

.filterList{
    display: none !important;

}

.btnSave{
    float: right;
    margin-bottom: -5px;
    margin-top: -5px;
}

.btnDisable
{
    display: none !important;
}

.tableTitle
{
    font-size: 1rem;
    font-weight: bold;
}

.statementSelections{
    display: none;
}

#HTimeline{
    position: absolute;
    z-index: 1;
    background: #ffffff80;
    margin-top: -50px;
    transition: all .5s ease-in-out;
    display: block;
}
#HTimeline>div>div>ul>li{
    background-image: none !important;
    top: 25px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.events-wrapper > .events{
    top:35px !important;
}
.events-wrapper{
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

@media only screen and (max-width: 1000px) and (min-width: 650px) {
    .events-wrapper {
        width: 85% !important;
    }
}
@media only screen and (max-width: 650px) and (min-width: 500px) {
    .events-wrapper {
        width: 80% !important;
    }
}
@media only screen and (max-width: 500px){
    .events-wrapper {
        width: 75% !important;
    }
}

.leaflet-topDown{
    margin-top: 50px !important;
}

.leaflet-top{
    transition: all .5s ease-in-out;
}
body {
    background-color: #272b34;
    font-family: 'Khula', sans-serif;
    font-weight: 300;
    color: white;
    line-height: 1em;
}
h3, h6 {
    margin: 0;
    line-height: 1em;
}
h3 {
    margin-bottom: 1em;
}
h6 {
    font-size: .8em;
    padding: 0 0 .5em;
    color: #919191;
}
figure {
    margin: 0 auto;
    max-width: 1100px;
    position: relative;
}
.graphic {
    padding-left: 30px;
}
.row {
    margin-bottom: 1.5em;
}
@-webkit-keyframes expand {
    from {width: 0%;}
    to {width: 100%;}
}
@keyframes expand {
    from {width: 0%;}
    to {width: 100%;}
}
@media screen and (min-width: 768px) {
    @-webkit-keyframes expand {
        from {width: 0%;}
        to {width: calc(100% - 75px);}
    }
    @keyframes expand {
        from {width: 0%;}
        to {width: calc(100% - 75px);}
    }
}
.chart {
    padding: 0 !important;
    width: 0%;
    -webkit-animation: expand 1.5s ease forwards;
            animation: expand 1.5s ease forwards;
}
.row + .row .chart {
    -webkit-animation-delay: .2s;
            animation-delay: .2s;
}
.row + .row + .row .chart {
    -webkit-animation-delay: .4s;
            animation-delay: .4s;
}
.block {
    display: block;
    height: 15px;
    color: #fff;
    font-size: .75em;
    float: left;
    background-color: #ffcc33;
    position: relative;
    overflow: hidden;
    opacity: 1;
    cursor: pointer;
    transition: all .5s ease-in-out;
}
.block:hover {
    background-color: #ffd65b;
}
.block:nth-of-type(2),
.legend li:nth-of-type(2):before {
    background-color: #276c4f;
}
.value{
    color: #00000000;
}
.block:nth-of-type(2):hover {
    background-color: #528972;
}
.block:nth-of-type(3),
.legend li:nth-of-type(3):before {
    background-color: #46acb1;
}
.block:nth-of-type(3):hover {
    background-color: #6abcc0;
}
.block:nth-of-type(4),
.legend li:nth-of-type(4):before {
    background-color: #96247b;
}
.block:nth-of-type(4):hover {
    background-color: #ab4f95;
}
.block:nth-of-type(5),
.legend li:nth-of-type(5):before {
    background-color: #343f96;
}
.block:nth-of-type(5):hover {
    background-color: #5c65ab;
}
.block:nth-of-type(6),
.legend li:nth-of-type(6):before {
    background-color: #f36523;
}
.block:nth-of-type(6):hover {
    background-color: #f5834e;
}
.block:nth-of-type(7),
.legend li:nth-of-type(7):before {
    background-color: #dee3dd;
}
.block:nth-of-type(7):hover {
    background-color: #e4e8e3;
}
.block:nth-of-type(8),
.legend li:nth-of-type(8):before {
    background-color: #c9222a;
}
.block:nth-of-type(8):hover {
    background-color: #d34e54;
}
.block:nth-of-type(9),
.legend li:nth-of-type(9):before {
    background-color: #25c97a;
}
.block:nth-of-type(9):hover {
    background-color: #50d394;
}
.block:nth-of-type(10),
.legend li:nth-of-type(10):before {
    background-color: #1d1d1b;
}
.block:nth-of-type(10):hover {
    background-color: #5C5C5A;
}
.value {
    width: 100%;
    height: 100%;
    display: block;
    line-height: 1em;
    position: absolute;
    /*top: 50%;*/
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}
.x-axis {
    text-align: center;
    padding: .5em 0 2em;
}
.y-axis {
    height: 20px;
    -webkit-transform: translate(-32px,170px) rotate(270deg);
            transform: translate(-32px,170px) rotate(270deg);
    position: absolute;
    left: 0;
}
@media screen and (min-width: 768px) {
    h6 {
        padding: 0;
        width: 75px;
        float: left;
        line-height: 100px;
    }
    .block {
        font-size: 1em;
    }
}


