body {
    background-color: #272b34;
    font-family: 'Khula', sans-serif;
    font-weight: 300;
    color: white;
    line-height: 1em;
}
h3, h6 {
    margin: 0;
    line-height: 1em;
}
h3 {
    margin-bottom: 1em;
}
h6 {
    font-size: .8em;
    padding: 0 0 .5em;
    color: #919191;
}
figure {
    margin: 0 auto;
    max-width: 1100px;
    position: relative;
}
.graphic {
    padding-left: 30px;
}
.row {
    margin-bottom: 1.5em;
}
@keyframes expand {
    from {width: 0%;}
    to {width: 100%;}
}
@media screen and (min-width: 768px) {
    @keyframes expand {
        from {width: 0%;}
        to {width: calc(100% - 75px);}
    }
}
.chart {
    padding: 0 !important;
    width: 0%;
    animation: expand 1.5s ease forwards;
}
.row + .row .chart {
    animation-delay: .2s;
}
.row + .row + .row .chart {
    animation-delay: .4s;
}
.block {
    display: block;
    height: 15px;
    color: #fff;
    font-size: .75em;
    float: left;
    background-color: #ffcc33;
    position: relative;
    overflow: hidden;
    opacity: 1;
    cursor: pointer;
    transition: all .5s ease-in-out;
}
.block:hover {
    background-color: #ffd65b;
}
.block:nth-of-type(2),
.legend li:nth-of-type(2):before {
    background-color: #276c4f;
}
.value{
    color: #00000000;
}
.block:nth-of-type(2):hover {
    background-color: #528972;
}
.block:nth-of-type(3),
.legend li:nth-of-type(3):before {
    background-color: #46acb1;
}
.block:nth-of-type(3):hover {
    background-color: #6abcc0;
}
.block:nth-of-type(4),
.legend li:nth-of-type(4):before {
    background-color: #96247b;
}
.block:nth-of-type(4):hover {
    background-color: #ab4f95;
}
.block:nth-of-type(5),
.legend li:nth-of-type(5):before {
    background-color: #343f96;
}
.block:nth-of-type(5):hover {
    background-color: #5c65ab;
}
.block:nth-of-type(6),
.legend li:nth-of-type(6):before {
    background-color: #f36523;
}
.block:nth-of-type(6):hover {
    background-color: #f5834e;
}
.block:nth-of-type(7),
.legend li:nth-of-type(7):before {
    background-color: #dee3dd;
}
.block:nth-of-type(7):hover {
    background-color: #e4e8e3;
}
.block:nth-of-type(8),
.legend li:nth-of-type(8):before {
    background-color: #c9222a;
}
.block:nth-of-type(8):hover {
    background-color: #d34e54;
}
.block:nth-of-type(9),
.legend li:nth-of-type(9):before {
    background-color: #25c97a;
}
.block:nth-of-type(9):hover {
    background-color: #50d394;
}
.block:nth-of-type(10),
.legend li:nth-of-type(10):before {
    background-color: #1d1d1b;
}
.block:nth-of-type(10):hover {
    background-color: #5C5C5A;
}
.value {
    width: 100%;
    height: 100%;
    display: block;
    line-height: 1em;
    position: absolute;
    /*top: 50%;*/
    left: 50%;
    transform: translate(-50%);
}
.x-axis {
    text-align: center;
    padding: .5em 0 2em;
}
.y-axis {
    height: 20px;
    transform: translate(-32px,170px) rotate(270deg);
    position: absolute;
    left: 0;
}
@media screen and (min-width: 768px) {
    h6 {
        padding: 0;
        width: 75px;
        float: left;
        line-height: 100px;
    }
    .block {
        font-size: 1em;
    }
}

